import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class WarehouseService implements OnDestroy {
  baseUrl = environment.baseUrl;
  specificClientWareHouses = new BehaviorSubject(null);
  allClientWareHouses$ = new BehaviorSubject(null);
  clearSLATableData = new BehaviorSubject(null);
  currentSelectedWarehouse$ = new BehaviorSubject(null);
  private destroyed$ = new Subject();
  constructor(private http: HttpClient) {}
  getWarehouses() {
    return this.http.get(this.baseUrl + '/inventory/sites');
  }
  getCurrentlySelectedWareHouse(): string {
    return this.currentSelectedWarehouse$.getValue();
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getDistributionByFacilityId(facilityIds: string[]) {
    const distributionCenters: string[] = [];

    this.specificClientWareHouses.getValue().forEach((warehouse: any) => {
      if (warehouse) {
          if (warehouse && facilityIds.includes(warehouse.facility_id)) {
            distributionCenters.push(warehouse.distribution_center);
          }
      }
    });

    return distributionCenters;
  }

  getWareHouseNameByDC(dc: string) {
    let warehouseName = '';
    this.specificClientWareHouses.getValue().forEach((warehouse: { distribution_center: string; warehouse_name: string; }) => {
      if (warehouse.distribution_center === dc) {
        warehouseName = warehouse.warehouse_name;
      }
    });

    return warehouseName;
  }
}
